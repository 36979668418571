import(/* webpackMode: "eager" */ "/home/ubuntu/fix_my_documents_nextjs_frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/fix_my_documents_nextjs_frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/fix_my_documents_nextjs_frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/fix_my_documents_nextjs_frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/fix_my_documents_nextjs_frontend/src/app/client-layout.tsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/fix_my_documents_nextjs_frontend/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/fix_my_documents_nextjs_frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
