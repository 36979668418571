// src/components/Notification.tsx

'use client';

import React, { createContext, useContext, ReactNode } from 'react';
import { notification } from 'antd';

// Define the shape of notification options
interface NotificationOptions {
  content: React.ReactNode;
  duration?: number;
}

// Define the context properties
interface NotificationContextProps {
  success: (options: NotificationOptions) => void;
  error: (options: NotificationOptions) => void;
  info: (options: NotificationOptions) => void;
  warning: (options: NotificationOptions) => void;
  loading: (options: NotificationOptions) => void;
}

// Default styles for notifications
const defaultStyle: React.CSSProperties = {
  marginTop: '2vh',
  width: '82vw',
  maxWidth: '625px',
};

// Create the Notification Context
const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

// NotificationProvider component that wraps around the app
export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  // Define notification methods with predefined configurations
  const notify: NotificationContextProps = {
    success: ({ content, duration = 3.0 }) => {
      api.success({
        message: 'Success',
        description: content,
        duration,
        placement: 'topRight', // Use string literal
        style: defaultStyle,
      });
    },
    error: ({ content, duration = 3.0 }) => {
      api.error({
        message: 'Error',
        description: content,
        duration,
        placement: 'topRight',
        style: defaultStyle,
      });
    },
    info: ({ content, duration = 3.0 }) => {
      api.info({
        message: 'Info',
        description: content,
        duration,
        placement: 'topRight',
        style: defaultStyle,
      });
    },
    warning: ({ content, duration = 3.0 }) => {
      api.warning({
        message: 'Warning',
        description: content,
        duration,
        placement: 'topRight',
        style: defaultStyle,
      });
    },
    loading: ({ content, duration = 3.0 }) => {
      api.open({
        message: 'Loading',
        description: content,
        duration,
        placement: 'topRight',
        style: defaultStyle,
      });
    },
  };

  return (
    <NotificationContext.Provider value={notify}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

// Custom hook to use the Notification context
export const useNotificationCustom = (): NotificationContextProps => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotificationCustom must be used within a NotificationProvider');
  }
  return context;
};
