// src/app/providers.tsx

'use client';

import React, { useEffect, ReactNode } from 'react';
import { ConfigProvider } from 'antd';
import { SessionProvider } from 'next-auth/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { NotificationProvider } from '@/components/Notification'; // Import the corrected NotificationProvider
import { initializeMetaPixel } from '@/utils/metaPixel';
import { initializeLinkedInTag } from '@/utils/linkedInTag';

import 'antd/dist/reset.css'; // Ensure Ant Design styles are imported
import './globals.css'; // Your global styles


const queryClient = new QueryClient();

export default function Providers({ children }: { children: ReactNode }) {
  useEffect(() => {
    initializeMetaPixel();
    initializeLinkedInTag();
  }, []);

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#00b96b' } }}>
      <SessionProvider>
        <QueryClientProvider client={queryClient}>
          <NotificationProvider>
            {children}
          </NotificationProvider>
        </QueryClientProvider>
      </SessionProvider>
    </ConfigProvider>
  );
}
